@import "@public/styles/mixins";
@import "@public/styles/variables";

.layout {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__content {
    background-color: $colorSecondary;
    flex: 1 0 auto;
  }

  &__scroll {
    position: fixed;
    bottom: 50px;
    right: 5px;
    z-index: 15;
    opacity: 0;
    transform: translateX(150%);
    transition: transform .3s linear;

    &Active {
      opacity: 1;
      transform: translateX(0);
    }

    &_btn {
      border: 1px solid #24822C;
      background-color: #24822C;
      border-radius: 50%;
      padding: 12px 7px;
      width: 39px;
      height: 39px;
      margin-right: 10px;
      box-shadow: 0 0 3px #000;
      cursor: pointer;

      &:before {
        content: '';
        display: block;
        width: 23px;
        height: 11px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 23px 11px;
        transform: rotate(90deg);
        background-image: url('../../../../assets/slider/arrow.svg');
      }
    }
  }
}
