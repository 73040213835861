@import "@public/styles/mixins";
@import "@public/styles/variables";

.modal {
  position: fixed;
  z-index: 30;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(25, 25, 25, 0.5);
  backdrop-filter: blur(10px);
  opacity: 0;
  animation: modal .3s forwards;

  @include deviceWidth(mobile) {
    display: block;
  }
}

@keyframes modal {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
