@import "@public/styles/variables.scss";
@import "@public/styles/mixins";

.textAria {
    margin-bottom: 16px;

    &__label {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 22px;
        text-transform: inherit;
        color: #1c1c1c;
    }

    &__input {
        @include m-typography(14px, 18px);
        width: 100%;
        height: 100px;
        border-radius: 19px;
        box-sizing: border-box;
        padding: 14px 27px;
        border-color: $colorPrimary;

        &:hover {
            border-color: $colorPrimary;
        }
        &:focus {
            outline: none;
        }
    }
}
